import React, {
  createContext,
  Dispatch,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';

const displayName = 'SettingsProvider';

const key = 'partner-settings';

type Settings = {
  [key: string]: unknown;
};

type SettingsValue = [Settings, Dispatch<Settings>];

const SettingsContext = createContext<SettingsValue>([
  {},
  () => {
    throw new Error('No <SettingsContext />');
  },
]);

export const useSettings = (): SettingsValue => useContext(SettingsContext);

const initialState = () => {
  try {
    return JSON.parse(localStorage.getItem(key) || '') as Settings;
  } catch (e) {
    return {};
  }
};

const SettingsProvider: FC = ({ children }) => {
  const [settings, setSettings] = useState(initialState);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(settings));
  }, [settings]);

  const updateSettings = (value: Settings) =>
    setSettings((prev) => ({ ...prev, ...value }));

  return (
    <SettingsContext.Provider value={[settings, updateSettings]}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.displayName = displayName;

export default SettingsProvider;
