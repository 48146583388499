import React, { lazy } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';

import paths from '../../paths.json';
import Auth from '../../routes/Auth';
import Login from '../../routes/Welcome/Login';
import Reset from '../../routes/Welcome/Reset';
import { useOrganization } from '../OrganizationProvider';
import SettingsProvider from '../SettingsProvider';

const displayName = 'App';

const Home = lazy(() =>
  import(
    /* webpackChunkName: "home" */
    '../../routes/Home'
  ),
);
const Welcome = lazy(() =>
  import(
    /* webpackChunkName: "welcome" */
    '../../routes/Welcome'
  ),
);

const App = () => {
  const organization = useOrganization();

  return (
    <>
      <Helmet>
        <link href={organization.logoUrl} rel="shortcut icon" />
      </Helmet>
      <Routes>
        <Route element={<Auth />} path={paths.auth} />
        <Route
          element={
            <Welcome>
              <Login />
            </Welcome>
          }
          path={paths.login}
        />
        <Route
          element={
            <Welcome>
              <Reset />
            </Welcome>
          }
          path={paths.reset}
        />
        <Route
          element={
            <SettingsProvider>
              <Home />
            </SettingsProvider>
          }
          path="*"
        />
      </Routes>
    </>
  );
};

App.displayName = displayName;

export default App;
