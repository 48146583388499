import { makeStyles } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';

import { useAuth } from '../../../components/AuthProvider';
import HeadTitle from '../../../components/HeadTitle';
import { required } from '../../../validate';
import FormField from '../FormField';

const displayName = 'Reset';

const useStyles = makeStyles(
  (theme) => ({
    submit: {
      marginTop: theme.spacing(3),
    },
  }),
  { name: displayName },
);

const Reset: FC = () => {
  const classes = useStyles();

  const { resetPassword } = useAuth();

  const onSubmit = useCallback(({ email }) => resetPassword(email), [
    resetPassword,
  ]);

  return (
    <>
      <HeadTitle>Reset Password</HeadTitle>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormField
              label="Email"
              name="email"
              type="email"
              validate={required}
            />
            <LoadingButton
              className={classes.submit}
              color="primary"
              fullWidth
              pending={submitting}
              type="submit"
              variant="contained"
            >
              Send email
            </LoadingButton>
          </form>
        )}
      </Form>
    </>
  );
};

Reset.displayName = displayName;

export default Reset;
