import {
  makeStyles,
  Snackbar,
  SnackbarProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import { AlertCircle } from 'mdi-material-ui';
import PropTypes from 'prop-types';
import React, { FC } from 'react';

const displayName = 'NotificationSnackbar';

const useStyles = makeStyles(
  (theme) => ({
    message: {
      alignItems: 'center',
      display: 'flex',
    },

    icon: {
      marginRight: theme.spacing(1),
    },

    error: {
      backgroundColor: theme.palette.error.dark,
    },
  }),
  { name: displayName },
);

type NotificationSnackbarProps = SnackbarProps & {
  variant?: 'error';
};

const NotificationSnackbar: FC<NotificationSnackbarProps> = ({
  ContentProps,
  message,
  variant,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const icon =
    variant === 'error' ? <AlertCircle className={classes.icon} /> : null;

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: 'center',
        vertical: smUp ? 'top' : 'bottom',
      }}
      ContentProps={{
        ...ContentProps,
        className: clsx(variant === 'error' && classes.error),
      }}
      message={
        variant ? (
          <div className={classes.message}>
            {icon}
            {message}
          </div>
        ) : (
          message
        )
      }
      {...rest}
    />
  );
};

NotificationSnackbar.propTypes = {
  ContentProps: PropTypes.object,
  message: PropTypes.node,
  variant: PropTypes.oneOf(['error']),
};

NotificationSnackbar.defaultProps = {
  ContentProps: undefined,
  message: null,
  variant: undefined,
};

NotificationSnackbar.displayName = displayName;

export default NotificationSnackbar;
