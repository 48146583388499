import { Container, ContainerProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { ElementType, FC } from 'react';

const useStyles = makeStyles(
  (theme) => ({
    center: {
      flex: 1,
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),

      '@supports (padding: max(0px))': {
        paddingLeft: `max(${theme.spacing(2)}, env(safe-area-inset-left))`,
        paddingRight: `max(${theme.spacing(2)}, env(safe-area-inset-right))`,

        [theme.breakpoints.up('sm')]: {
          paddingLeft: `max(${theme.spacing(3)}, env(safe-area-inset-left))`,
          paddingRight: `max(${theme.spacing(3)}, env(safe-area-inset-right))`,
        },

        [theme.breakpoints.up('md')]: {
          paddingLeft: `max(${theme.spacing(4)}, env(safe-area-inset-left))`,
          paddingRight: `max(${theme.spacing(4)}, env(safe-area-inset-right))`,
        },
      },
    },
  }),
  { name: 'CenterContainer' },
);

type CenterContainerProps = ContainerProps & {
  component?: ElementType;
};

const CenterContainer: FC<CenterContainerProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Container
      className={clsx(classes.center, className)}
      maxWidth="lg"
      {...rest}
    />
  );
};

export default CenterContainer;
