import blue from '@material-ui/core/colors/blue';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';

const palette = {
  primary: {
    main: blue[500],
  },
};

const typography = {
  fontFamily: 'Lato, sans-serif',
};

// https://github.com/mui-org/material-ui/blob/27471b4564eb40ff769352d73a29938d25804e45/packages/material-ui/src/styles/createTypography.js#L3
const round = (value: number) => {
  return Math.round(value * 1e5) / 1e5;
};

const { pxToRem } = createTypography(createPalette(palette), typography);

export const options: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '8px 16px',
          fontSize: pxToRem(14),
          lineHeight: `${round(16 / 14)}em`,
        },
      },
    },
  },
  palette,
  shape: {
    borderRadius: 2,
  },
  typography,
};
