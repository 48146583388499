import auth0 from 'auth0-js';
import PropTypes from 'prop-types';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { getBaseDomain } from '../../location';
import paths from '../../paths.json';
import { useNotification } from '../NotificationProvider';
import { useOrganization } from '../OrganizationProvider';

const displayName = 'AuthProvider';

const connection = 'Publishers';

const key = 'partner-auth';

let tokenRenewalTask: number | null = null;

type Auth = {
  accessToken: string;
  authorize: () => void;
  authorized: boolean;
  idToken: string;
  logIn: (email: string, password: string) => Promise<void>;
  logOut: () => void;
  resetPassword: (email: string) => Promise<void>;
};

const AuthContext = createContext<Auth | undefined>(undefined);

export const useAuth = (): Auth => {
  const auth = useContext(AuthContext);

  if (!auth) {
    throw new Error('No <AuthProvider />');
  }

  return auth;
};

type AuthState = {
  accessToken: string;
  expiresAt: number;
  idToken: string;
};

type AuthResult = {
  accessToken: string;
  expiresIn: number;
  idToken: string;
};

const init = () => {
  try {
    const redux = JSON.parse(localStorage.getItem('publisher-ui') || '') as {
      auth: AuthState;
    };

    localStorage.removeItem('publisher-ui');

    return redux.auth;
  } catch (e) {
    // cleanup done
  }

  try {
    return JSON.parse(localStorage.getItem(key) || '') as AuthState;
  } catch (e) {
    return undefined;
  }
};

const AuthProvider: FC = ({ children }) => {
  const navigate = useNavigate();

  const [, { setError, setNotification }] = useNotification();
  const organization = useOrganization();

  const auth = useMemo(
    () =>
      new auth0.WebAuth({
        audience: 'https://api.weq.host/',
        clientID: organization.auth.clients.partner,
        domain: organization.auth.domain,
        redirectUri: `${window.location.origin}${paths.auth}?cache=${CACHE}`,
        responseType: 'token id_token',
        scope: 'openid profile',
      }),
    [organization.auth.clients.partner, organization.auth.domain],
  );

  const sameOrigin = useMemo(
    () =>
      getBaseDomain(window.location.hostname) ===
      getBaseDomain(organization.auth.domain),
    [organization.auth.domain],
  );

  const [state, setState] = useState<AuthState | undefined>(init);

  const { accessToken = '', expiresAt = 0, idToken = '' } = state || {};

  const reset = useCallback(() => setState(undefined), []);

  const setAuth = useCallback(
    (payload: { accessToken: string; expiresIn: number; idToken: string }) => {
      const { accessToken, expiresIn, idToken } = payload;
      const expiresAt = Date.now() + expiresIn * 1000;

      setState({
        accessToken,
        expiresAt,
        idToken,
      });
    },
    [],
  );

  const authorize = useCallback(() => {
    auth.parseHash((err, authResult) => {
      if (err) {
        reset();
        setError(err.errorDescription || err.toString());
        navigate(paths.login, { replace: true });
      }

      if (authResult) {
        setAuth(authResult as AuthResult);
        navigate(paths.home, { replace: true });
      }
    });
  }, [auth, navigate, reset, setAuth, setError]);

  const authorized = expiresAt > Date.now();

  const resetPassword = useCallback(
    (email: string) =>
      new Promise<void>((resolve) => {
        auth.changePassword(
          {
            connection,
            email,
          },
          (err, message) => {
            if (err) {
              setError(err.description || err.toString());
            }

            if (message) {
              setNotification(message);
            }

            resolve();
          },
        );
      }),
    [auth, setError, setNotification],
  );

  const logIn = useCallback(
    (email: string, password: string) =>
      new Promise<void>((resolve) => {
        const context = sameOrigin ? auth : auth.client;

        context.login(
          {
            password,
            realm: connection,
            username: email,
          },
          (err, authResult) => {
            if (err) {
              setError(err.description || err.toString());
            }

            if (authResult) {
              setAuth(authResult);
              navigate(paths.home);
            }

            resolve();
          },
        );
      }),
    [auth, navigate, sameOrigin, setAuth, setError],
  );

  const logOut = useCallback(() => {
    if (sameOrigin) {
      localStorage.removeItem(key);
      auth.logout({
        returnTo: `${window.location.origin}${paths.login}`,
      });
    } else {
      navigate(paths.login);
      reset();
    }
  }, [auth, navigate, reset, sameOrigin]);

  useEffect(() => {
    if (!state) {
      localStorage.removeItem(key);

      return;
    }

    localStorage.setItem(key, JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    if (!accessToken || !sameOrigin) {
      return;
    }

    const now = Date.now();

    tokenRenewalTask = window.setTimeout(() => {
      tokenRenewalTask = null;

      auth.checkSession({}, (err, authResult) => {
        if (authResult) {
          setAuth(authResult);
        } else {
          reset();
          navigate(paths.login);
        }
      });
    }, Math.max(expiresAt - now - 60e3, 0));

    return () => {
      if (tokenRenewalTask) {
        window.clearTimeout(tokenRenewalTask);
        tokenRenewalTask = null;
      }
    };
  }, [accessToken, auth, expiresAt, navigate, reset, sameOrigin, setAuth]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        authorize,
        authorized,
        idToken,
        logIn,
        logOut,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

AuthProvider.displayName = displayName;

export default AuthProvider;
