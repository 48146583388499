import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import React, { createContext, FC, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';

const displayName = 'ConfigProvider';

type Config = {
  datagon: string;
  environment: string;
  googleAnalytics: boolean;
  organizationBucket: string;
};

const ConfigContext = createContext<Config | undefined>(undefined);

const useConfig = () => {
  const config = useContext(ConfigContext);

  if (!config) {
    throw new Error('No config');
  }

  return config;
};

export const useDatagon = (): Config['datagon'] => useConfig().datagon;
export const useGoogleAnalytics = (): Config['googleAnalytics'] =>
  useConfig().googleAnalytics;
export const useOrganizationBucket = (): Config['organizationBucket'] =>
  useConfig().organizationBucket;

const loadConfig = async () => {
  const res = await fetch(`/config.json?cache=${CACHE}`);

  try {
    return (await res.json()) as Config;
  } catch (e) {
    throw new Error('Failed to load config');
  }
};

const ConfigProvider: FC = ({ children }) => {
  const { data: config } = useQuery<Config, string>('config', loadConfig, {
    suspense: true,
  });

  useEffect(() => {
    if (!config) {
      return;
    }

    const { environment } = config;

    if (environment !== 'development') {
      const options: Sentry.BrowserOptions = {
        dsn: 'https://c240afe82435489d9b8d92d05b085e46@sentry.io/1328769',
        environment,
      };

      if (['staging', 'production'].includes(environment)) {
        options.release = `partner-${VERSION}`;
      }

      Sentry.init(options);
    }
  }, [config]);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

ConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ConfigProvider.displayName = displayName;

export default ConfigProvider;
