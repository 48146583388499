import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import App from '../App';
import AuthProvider from '../AuthProvider';
import ConfigProvider from '../ConfigProvider';
import Exception from '../Exception';
import GoogleAnalytics from '../GoogleAnalytics';
import Loading from '../Loading';
import NotificationProvider from '../NotificationProvider';
import OrganizationProvider from '../OrganizationProvider';
import ThemeProvider from '../ThemeProvider';

const reactQueryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
  },
};

const Root = () => (
  <ErrorBoundary fallback={({ error }) => <Exception error={error} />}>
    <BrowserRouter>
      <ReactQueryConfigProvider config={reactQueryConfig}>
        <ThemeProvider>
          <NotificationProvider>
            <Suspense fallback={<Loading />}>
              <ConfigProvider>
                <OrganizationProvider>
                  <AuthProvider>
                    <App />
                    <GoogleAnalytics />
                  </AuthProvider>
                </OrganizationProvider>
              </ConfigProvider>
            </Suspense>
          </NotificationProvider>
        </ThemeProvider>
      </ReactQueryConfigProvider>
    </BrowserRouter>
  </ErrorBoundary>
);

Root.displayName = 'Root';

export default Root;
