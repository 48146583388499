import PropTypes from 'prop-types';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import NotificationSnackbar from '../NotificationSnackbar';

const displayName = 'NotificationProvider';

type Notification = {
  message: string;
  variant?: 'error';
};

type NotificationTuple = [
  Notification | undefined,
  {
    resetNotification: () => void;
    setError: (message: string) => void;
    setNotification: (message: string) => void;
  },
];

const NotificationContext = createContext<NotificationTuple | undefined>(
  undefined,
);

export const useNotification = (): NotificationTuple => {
  const notificationTuple = useContext(NotificationContext);

  if (!notificationTuple) {
    throw new Error('No <NotificationProvider />');
  }

  return notificationTuple;
};

const NotificationProvider: FC = ({ children }) => {
  const [notification, setNotification] = useState<Notification | undefined>();

  const lastRef = useRef(notification);

  if (notification) {
    lastRef.current = notification;
  }

  const setError = useCallback((message: string) => {
    setNotification({
      message,
      variant: 'error',
    });
  }, []);

  return (
    <NotificationContext.Provider
      value={[
        notification,
        {
          resetNotification: () => setNotification(undefined),
          setError,
          setNotification: (message) =>
            setNotification({
              message,
            }),
        },
      ]}
    >
      {children}
      <NotificationSnackbar
        {...lastRef.current}
        onClose={() => setNotification(undefined)}
        open={Boolean(notification)}
      />
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

NotificationProvider.displayName = displayName;

export default NotificationProvider;
