import { Link, makeStyles } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import React, { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import { Link as RouteLink } from 'react-router-dom';

import { useAuth } from '../../../components/AuthProvider';
import HeadTitle from '../../../components/HeadTitle';
import paths from '../../../paths.json';
import { required } from '../../../validate';
import FormField from '../FormField';

const displayName = 'Login';

const useStyles = makeStyles(
  (theme) => ({
    reset: {
      margin: 0,
      textAlign: 'center',
    },

    submit: {
      marginTop: theme.spacing(3),
    },

    divider: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
    },

    accountText: {
      textAlign: 'center',
    },
  }),
  { name: displayName },
);

const Login: FC = () => {
  const classes = useStyles();

  const { logIn } = useAuth();

  const onSubmit = useCallback(
    ({ email, password }) => logIn(email, password),
    [logIn],
  );

  return (
    <>
      <HeadTitle>Login</HeadTitle>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormField
              label="Email"
              name="email"
              type="email"
              validate={required}
            />
            <FormField
              label="Password"
              name="password"
              type="password"
              validate={required}
            />
            <p className={classes.reset}>
              {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
              <Link component={RouteLink} to={paths.reset}>
                Don’t remember your password?
              </Link>
            </p>
            <LoadingButton
              className={classes.submit}
              color="primary"
              fullWidth
              pending={submitting}
              type="submit"
              variant="contained"
            >
              Log in
            </LoadingButton>
          </form>
        )}
      </Form>
    </>
  );
};

Login.displayName = displayName;

export default Login;
