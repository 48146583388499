import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
  useMediaQuery,
} from '@material-ui/core';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import PropTypes from 'prop-types';
import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { options } from '../../theme';

const displayName = 'ThemeProvider';

type ThemeContextType = {
  themeOptions: ThemeOptions;
  setThemeOptions: Dispatch<SetStateAction<ThemeOptions>>;
};

const ThemeContext = createContext<ThemeContextType>({
  themeOptions: options,
  setThemeOptions: () => undefined,
});

export const useThemeOptions = (): ThemeContextType => useContext(ThemeContext);

const ThemeProvider: FC = ({ children }) => {
  const dark = useMediaQuery('(prefers-color-scheme: dark)', {
    noSsr: true,
  });

  const type = dark ? 'dark' : 'light';

  const [themeOptions, setThemeOptions] = useState<ThemeOptions>(() => ({
    ...options,
    palette: {
      ...options.palette,
      type,
    },
  }));

  useEffect(() => {
    if (type !== themeOptions.palette?.type) {
      setThemeOptions({
        ...themeOptions,
        palette: {
          ...themeOptions,
          type,
        },
      });
    }
  }, [themeOptions, type]);

  const theme = useMemo(() => createMuiTheme(themeOptions), [themeOptions]);

  return (
    <ThemeContext.Provider
      value={{
        setThemeOptions,
        themeOptions,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

ThemeProvider.displayName = displayName;

export default ThemeProvider;
