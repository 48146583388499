import { parseDomain, ParseResultType } from 'parse-domain';
import { parse, ParsedQs, stringify } from 'qs';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const getBaseDomain = (domain: string): string => {
  const result = parseDomain(domain);

  if (result.type === ParseResultType.Listed) {
    return [result.icann.domain, ...result.icann.topLevelDomains].join('.');
  }

  if (result.type === ParseResultType.Invalid) {
    return domain;
  }

  return result.hostname;
};

export const parseSearch = (search: string): ParsedQs =>
  parse(search, { ignoreQueryPrefix: true });

export const createSearch = (query: unknown): string =>
  stringify(query, { addQueryPrefix: true });

export const useSearchQuery = (): ParsedQs => {
  const { search } = useLocation();

  return useMemo(() => parseSearch(search), [search]);
};

export const useUpdateSearch = (): ((
  query: Record<string, unknown>,
) => void) => {
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback(
    (query) => {
      const currentQuery = parseSearch(location.search);

      navigate({
        search: createSearch({
          ...currentQuery,
          ...query,
        }),
      });
    },
    [location.search, navigate],
  );
};
