import { TextField, TextFieldProps } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

const displayName = 'FormField';

type FormFieldProps<FieldValue = string> = FieldProps<
  FieldValue,
  FieldRenderProps<FieldValue>
> & {
  label: TextFieldProps['label'];
  type: TextFieldProps['type'];
};

const FormField = <FieldValue,>({
  label,
  type,
  ...props
}: FormFieldProps<FieldValue>): JSX.Element => (
  <Field {...props}>
    {({ input, meta: { error, touched } }) => (
      <TextField
        {...input}
        error={touched && Boolean(error)}
        fullWidth
        helperText={(touched && (error as string)) || ' '}
        label={label}
        margin="normal"
        required
        type={type}
      />
    )}
  </Field>
);

FormField.propTypes = {
  label: PropTypes.node.isRequired,
  type: PropTypes.string,
};

FormField.defaultProps = {
  type: 'text',
};

FormField.displayName = displayName;

export default FormField;
