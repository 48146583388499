import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../components/AuthProvider';
import paths from '../../paths.json';

const displayName = 'Auth';

const authRegExp = /access_token|id_token|error/;

const Auth: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { authorize } = useAuth();

  const { hash } = location;
  const hasHash = authRegExp.test(hash);

  useEffect(() => {
    if (!hasHash) {
      navigate(paths.login, { replace: true });

      return;
    }

    authorize();
  }, [authorize, hasHash, navigate]);

  return null;
};

Auth.displayName = displayName;

export default Auth;
