import { parseDomain, ParseResultType } from 'parse-domain';
import React, { createContext, FC, useContext, useEffect } from 'react';
import { useQuery } from 'react-query';

import { useOrganizationBucket } from '../ConfigProvider';
import { useThemeOptions } from '../ThemeProvider';

const displayName = 'OrganizationProvider';

type Organization = {
  auth: {
    clients: {
      partner: string;
    };
    domain: string;
  };
  company: string;
  datagonUrl: string;
  id: string;
  logoUrl: string;
  name: string;
  styles: {
    colors: {
      primary: string;
    };
  };
};

const OrganizationContext = createContext<Organization | undefined>(undefined);

export const useOrganization = (): Organization => {
  const organization = useContext(OrganizationContext);

  if (!organization) {
    throw new Error('No organization');
  }

  return organization;
};

const loadOrganization = async (organizationBucket: string) => {
  const { hostname } = window.location;
  const result = parseDomain(hostname);

  let domain;

  if (result.type === ParseResultType.Listed && result.icann.domain) {
    domain = result.icann.domain;

    if (domain === 'amazonaws') {
      domain = 'swaarm';
    }
  }

  if (result.type === ParseResultType.Reserved) {
    domain = 'swaarm';
  }

  if (!domain) {
    throw new Error(`Unknown organization on domain "${hostname}"`);
  }

  try {
    const res = await fetch(
      `${organizationBucket}/${domain}.json?cache=${Date.now()}`,
    );

    return (await res.json()) as Organization;
  } catch (e) {
    throw new Error(
      `Loading config for organization for domain "${hostname}" failed!`,
    );
  }
};

const OrganizationProvider: FC = ({ children }) => {
  const organizationBucket = useOrganizationBucket();

  const { setThemeOptions } = useThemeOptions();

  const { data } = useQuery<Organization, string>(
    organizationBucket,
    loadOrganization,
    {
      suspense: true,
    },
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    setThemeOptions((options) => ({
      ...options,
      palette: {
        ...options.palette,
        secondary: {
          ...options.palette?.secondary,
          main: data.styles.colors.primary,
        },
      },
    }));
  }, [data, setThemeOptions]);

  return (
    <OrganizationContext.Provider value={data}>
      {children}
    </OrganizationContext.Provider>
  );
};

OrganizationProvider.displayName = displayName;

export default OrganizationProvider;
