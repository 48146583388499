import PropTypes from 'prop-types';
import { FC, useEffect } from 'react';

type HeadTitleProps = {
  children: string;
};

const HeadTitle: FC<HeadTitleProps> = ({ children }) => {
  useEffect(() => {
    document.title = children;
  }, [children]);

  return null;
};

HeadTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

HeadTitle.displayName = 'HeadTitle';

export default HeadTitle;
